import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"
import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    <div className="heart mobile">
      <FontAwesomeIcon icon={faHeart} />
    </div>
    <BrandContainer>
      <div className="logo">
        <span className="logo__text">ZIOOM</span>
        <div className="logo__dot"></div>
      </div>
    </BrandContainer>
    <FooterContainer>
      <div className="heart">
        <FontAwesomeIcon icon={faHeart} />
      </div>
      <span>
        RAKS SP. Z O.O. <br />
        Kamionkowska 51, 03-812 Warszawa
      </span>
      <span className="hyperlinks">
        <a
          href="https://zioom.pl/assets/regulamin_serwisu.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Regulamin
        </a>{" "}
        i{" "}
        <a
          href="https://zioom.pl/assets/polityka_prywatnosci.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Polityka prywatności
        </a>
      </span>
    </FooterContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  margin: 72px 0 0;
  padding: 0 0 80px;

  .heart {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
    background-color: ${props => props.theme.color.accentBlue};
    color: ${props => props.theme.color.background.white};
    font-size: 28px;
    text-align: center;
    padding-top: 12px;
  }

  .heart.mobile {
    display: none;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    margin: 0;
    background-color: inherit;
    display: grid;
    justify-items: center;
    padding: 0 0 48px;

    .heart {
      display: none;
    }

    .heart.mobile {
      margin: 0 0 24px 0;
      display: block;
    }
  }
`

const BrandContainer = styled(Container)`
  padding: 0 0 36px 0;
  @media (max-width: ${props => props.theme.screen.md}) {
    display: grid;
    justify-items: center;
  }
`
const FooterContainer = styled(Container)`
  padding: 0;
  display: grid;
  grid-template-columns: 56px auto 1fr;
  grid-column-gap: 24px;
  align-items: center;

  span {
    font-family: ${props => props.theme.font.primary.regular};
    font-size: ${props => props.theme.font_size.xsmall};
    color: ${props => props.theme.color.secondary.high};
    line-height: 1.75;

    br {
      display: none;
    }
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.color.accentBlue};
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: auto;
    padding: 0 24px;
    justify-items: center;
    text-align: center;

    span {
      font-size: ${props => props.theme.font_size.xxsmall};
      line-height: 1.5;

      br {
        display: block;
      }
    }

    .hyperlinks {
      font-size: 11px;
      line-height: 28px;
      margin-top: 12px;
    }
  }
`

export default Footer
