const theme = {
  font: {
    primary: {
      light: "Ubuntu-Light",
      regular: "Ubuntu-Regular",
      medium: "Ubuntu-Medium",
      bold: "Ubuntu-Bold",
    },
  },

  font_size: {
    xxsmall: "14px",
    xsmall: "16px",
    small: "18px",
    regular: "20px",
    large: "22px",
    xlarge: "24px",
    xxlarge: "36px",
    xxxlarge: "40px",
    xxxxlarge: "56px",
  },

  color: {
    primary: {
      high: "#183b56",
      medium: "#5a7184",
    },
    secondary: {
      high: "#5a7184",
      medium: "#b3bac5",
    },
    accentBlue: "#1565d8",
    accentGreen: "#36b37e",
    errorText: "#d0021b",
    errorBackground: "rgba(230, 72, 103, 0.23)",
    background: {
      white: "#ffffff",
      light: "#f8fbff",
      green: "rgba(54, 179, 126, 0.1)",
    },
  },

  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
  },
}

export default theme
